import React, { useEffect, useRef, useState } from 'react'
import {motion, useScroll, useTransform} from 'framer-motion'
import Slide from './Slide'
import { useParams } from 'react-router-dom'
import MobileSlide from './MobileSlide'


const Slider = ({}) => {
    const {title} = useParams();
    const section = useRef(null)

    const [project, setProject] = useState(null)
    

    const {scrollYProgress} = useScroll({
        target: section,
        offset: ['start end', 'end start']
    })

    const translateX = useTransform(scrollYProgress, [0.4, 0.6], ['0%', '-200%'])

    const fetchProject = async() => {
        try {
            const response = await fetch('/projects.json')
            const data = await response.json();

          
            const project = data.find((item) => item.title === title)
            setProject(project)
        

        } catch (error) {
            
        }
        
    }

    useEffect(() => {
        fetchProject();
    }, [title])
   

   //Make the height larger to limit the boundaries...
   //100vh -- animation runs to quickly



  return (
    <>
    <section className="block py-20 h-auto md:py-40 md:h-[200vh] bg-gradient-to-b to-[var(--dark-background)] from-black overflow-clip" ref={section}>
        {/* Desktop Scroll Slider */}
        {/* <div className="max-w-[80%] md:max-w-[90%] mx-auto translate-y-[50px]">
          <h2 className="heading">Features</h2>
          </div> */}
    
        <motion.div id="slider" className='hidden md:flex sticky top-20 pt-10' style={{x: translateX}}>
            {project && 
                <> 
                    <Slide
                        heading={project.features[0].type}
                        src={project.screens[0]}
                        feature={project.features[0].content}
                        index={0}/>
                    <Slide
                        heading={project.features[1].type}
                        src={project.screens[1]}
                        feature={project.features[1].content}
                        index={1}/>
                    <Slide
                        heading={project.features[2].type}
                        src={project.screens[2]}
                        feature={project.features[2].content}
                        index={2}/> 
                </> 
            }
        </motion.div>
     

            
        {/* Mobile */}
        <div className="container">
            <div className="grid md:hidden grid-cols-1">
                {project && 
                    <>
                        <MobileSlide
                            heading={project.features[0].type}
                            src={project.screens[0]}
                            feature={project.features[0].content}
                            index={0}/>
                        <MobileSlide
                            heading={project.features[1].type}
                            src={project.screens[1]}
                            feature={project.features[1].content}
                            index={1}/>
                        <MobileSlide 
                            heading={project.features[2].type}
                            src={project.screens[2]}
                            feature={project.features[2].content}
                            index={2}/>
                     </>
                }
            </div>
         </div>
        
    </section>
    </>
  )
}

export default Slider