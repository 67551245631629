import React from 'react'

const AboutMe = () => {
  return (
    <section id='about-me' className="py-12 md:py-20 md:pt-32 section-gradient relative">
        <div className="container">
          <div className='absolute top-[5%] right-[5%] bg-gradient-1 blur-xl rounded-full w-[80px] h-[80px]  md:w-[100px] md:h-[100px] z-0 opacity-25'></div>
          <div className='absolute top-[10%] right-[90%] bg-gradient-2 blur-xl rounded-full w-[80px] h-[80px] md:w-[100px] md:h-[100px] z-0 opacity-25'></div>
          <div className='absolute top-[70%] right-[20%] bg-gradient-3 blur-xl rounded-full w-[80px] h-[80px] md:w-[100px] md:h-[100px] z-0 opacity-25'></div>
        

            <p className='mb-10 text-md leading-[2.5rem] md:text-2xl md:leading-[3rem] z-10 font-bold'>
              <span className='hidden md:inline'> I hold a Bachelor's degree in Marketing from Western Connecticut State University, where I graduated in 2022.  Since then,</span>
              <span className='hidden md:inline'>I have immersed myself in the dynamic world of front-end development as a freelance professional.</span>
                My passion lies in creating visually appealing and user-friendly interfaces, and I thrive on the ever-evolving challenges of web development.
            </p>

        </div>
    </section>
  )
}

export default AboutMe

// I am excited to continue shaping engaging online experiences and contributing to the evolving landscape of web development