import React from 'react'

const Slide = ({heading, src, feature, index}) => {
  return (
    <div className="slide min-w-[100vw] grid grid-cols-1 md:grid-cols-[1.2fr,1fr] gap-20 items-center py-20">
                <img className='min-w-full max-w-full h-auto' src={src}/>

                <div className='pb-12 pr-16'>
                    <h2 className='text-4xl font-bold mb-5'><span className='mr-2'>0{index + 1}</span> {heading}</h2>

                   <p className='text-lg leading-[3rem]'>{feature}</p>
                </div>
            </div>
  )
}

export default Slide