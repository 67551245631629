import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";
import { FaGithub } from 'react-icons/fa6';

const ProjectHero = ({image, mobileImage, title, credentials, frameworks, about, src, gitLink}) => {

  return (
    <section className='relative bg-black md:min-h-[900px] md:max-h-[800px] md:min-h-none'>
        {/* Gradient */}
         <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', background: 'linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,1)) 100%'}}></div>  


        {/* Image */}
        <div className="max-w-full mx-auto">
            <img className='hidden md:block w-full h-auto min-h-[800px] md:max-h-[800px] md:min-h-none md:rounded-lg object-cover' src={image} alt="" />
            <img className='md:hidden w-full h-auto min-h-[800px] max-h-[800px] md:min-h-none md:rounded-lg object-cover' src={mobileImage} alt="" />
        </div>

        <div className="absolute z-20 top-0 left-0 min-h-full py-12 md:py-24 min-w-full">
            <div className='max-w-[80%] md:max-w-[70%] mx-auto'>
            <h1 className="text-2xl md:text-6xl font-bold mb-6 md:mb-16">{title}</h1>
    
            <div className="flex flex-col-reverse md:mt-0 md:flex-row justify-between items-start gap-[20px] md:gap-[130px]">
                <div className='hidden md:flex md:flex-col p-0 mt-0'>
                    {frameworks && frameworks.map((item, index) => {
                      return <p key={item} className={`${index === 0 && 'mt-[3px]'} text-[#ccc] text-md md:text-2xl mb-4`}>{item}</p>
                    })}
                </div>

                <div>
                
                   {about &&  
                    <>
                    <p className='mb-10 text-md leading-[2.5rem] md:text-2xl md:leading-[3rem]'>{about[0]}</p>
                    <p className='text-md leading-[2.5rem] md:text-2xl md:leading-[3rem]'>{about[1]}</p>
                    </>
                   } 

                   {credentials && 
                   <div className=''>
                    <p className='text-md leading-[2.5rem] md:text-2xl md:leading-[3rem] mb-3'>** To explore the live version of this project, please use the following credentials to login: **</p>
                    <p className='text-md leading-[2.5rem] md:text-2xl md:leading-[3rem] mb-1 lowercase'>{credentials[0]}</p>
                    <p className='text-md leading-[2.5rem] md:text-2xl md:leading-[3rem] lowercase'>{credentials[1]}</p>
                    </div>
                   }

                    <div className='flex flex-col md:flex-row items-center mt-16'>
                        <a target='_blank' href={src} className='min-w-full md:min-w-0 mb-4 md:mb-0 md:mr-5 button-primary px-8 py-4 flex justify-center items-center shadow-2xl'>
                          <p>Live Site</p>
                          <span className='text-xl ml-4'><FaArrowRightLong/></span>
                        </a>

                        <a target='_blank' href={gitLink} className='min-w-full md:min-w-0 button-secondary px-8 py-4 flex justify-center items-center shadow-2xl'>
                           <p>Github</p>
                          <span className='text-xl ml-4'><FaGithub/></span>
                        </a>
                    </div>
                </div>

            </div>
            </div>
        </div>
    </section>
  )
}

export default ProjectHero