import React from 'react'

const MobileSlide = ({heading, src, feature, index}) => {
  return (
   <div className="slide min-w-[100%] grid grid-cols-1 gap-12 md:gap-20 items-center py-10">
                <img className={`${index === 0 && 'translate-x-[-10px]'} min-w-full max-w-full h-auto mx-auto`} src={src}/>

                <div className='pl-3'>
                    <h2 className='text-2xl font-bold mb-5'><span className='mr-2'>0{index + 1}</span> {heading}</h2>

                   <p className='text-md leading-[2rem]'>{feature}</p>
                </div>
            </div>
  )
}

export default MobileSlide