import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FaArrowRightLong } from 'react-icons/fa6';

const Project = ({title, image, intro, color, index, frameworks}) => {

    const [loading, setLoading] = useState(false)

    const handleImageLoad = () => {
        setLoading(true)
        const imageLoad = new Image();
        imageLoad.src = image

     
        imageLoad.onload = () => {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleImageLoad();
    }, [])


    if(loading){
        return <h2>Loading...</h2>
    }

    return (
    //     <Link to={`/project/${title}`}>
    //    <div className="card rounded-lg bg-[#222] text-[var(--dark-text)] shadow-xl">
    //         <img className='rounded-t-lg min-w-full' src={image} alt={title}/>

    //         <div className="card-content p-6 md:p-8">
    //             <h2 className='text-xl md:text-2xl mb-3 md:mb-5 font-bold'>{title}</h2>
    //             <p className='mb-6 md:mb-10 text-sm md:text-lg'>{intro}</p>
                
    //         </div>
    //    </div>
    //    </Link>

        <section className={`${index % 2 && 'reverse'} mb-5 md:mb-10 pb-10 md:pb-20 border-b-4 border-[var(--dark-text)] relative z-40`}>
            <h1 className='font-bold text-5xl md:text-7xl'>0{index + 1}</h1>

            <div className="grid md:grid-cols-[1.25fr,1fr] gap-5 md:gap-10 mt-5 md:mt-10">
                <img className='max-w-full min-w-full rounded-lg' src={image}/>
        

                <div className='project-content px-3 md:px-0 pt-3 md:pt-10 relative'>
                    <h1 className='text-2xl md:text-5xl font-bold '>{title}</h1>
                    {/* <div className='mt-6 mb-10'>
                        {frameworks.map((item, index) => {
                            return <span className='mr-4 bg-black shadow-lg px-4 py-2 rounded-sm'>{item}</span>
                        })}
                    </div> */}
                    <p className='leading-[1.75rem] md:leading-[2rem] my-6 md:my-10'>{intro}</p>


                     <Link to={`/project/${title}`}>
                     <button className='button-primary mt-8 md:mt-20 flex items-center text-md md:text-xl bg-[#222] shadow-xl px-6 py-3 rounded-sm font-bold'>
                        <span className='mr-4'>View Project</span>
                        <span>&rarr;</span>  

                    </button>
                    </Link>

                    
                </div>
            </div>
        </section>

   

      
        //    <section style={{ background: 'radial-gradient(circle, #1A1C1E, #111)' }} className="text-center  text-white mb-20 ">
        //     <div className='py-20 w-full relative border-t-2 border-[#B7B6B6]'>
        //          <h1 className='font-bold text-4xl  relative z-10'>{title}</h1>
        //         <p className='mt-4  relative z-10 text-xl'>{intro}</p>
        //         <div style={{background: `#${color}`}} className={`absolute top-[75%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50 rounded-full blur-3xl shadow-2xl h-[300px] w-[300px] z-0`}></div>
        //     </div>
        
        //     <img className='rounded-lg min-w-full relative z-20' src={image} alt={title}/>

        // </section>
    )
}

export default Project