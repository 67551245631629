import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

const UIShots = ({next, conclusion}) => {

  return (
    <section className="pt-20 pb-40">
      <div className="max-w-[80%] md:max-w-[90%] mx-auto">
        <h2 className="heading">Conclusion</h2>
       

         <p className='text-md leading-[2.5rem] md:text-2xl md:leading-[3rem]'>{conclusion}</p>
          <Link to={`/project/${next}`} className='min-w-full md:min-w-0 md:max-w-[260px] mt-10 md:mt-20 button-primary px-8 py-4 flex justify-center items-center'>
                <p>Next Project</p>
                <span className='text-xl ml-4'><FaArrowRightLong/></span>
            </Link>
         </div>

    </section>
  )
}

export default UIShots