import React, { useEffect } from 'react'
import { FaArrowRightLong } from 'react-icons/fa6';
import { FaGithub } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const Hero = () => {

 const scrollToSection = () => {
  const targetSection = document.getElementById('footer');

  const target = targetSection.getBoundingClientRect().top;

  if(targetSection){
       window.scrollTo({
      top: target,
      behavior: 'smooth'
    });
  }
  }

 
  


  return (

    <section id='home' className='h-[80vh] md:h-[90vh] relative overflow-hidden'>
      {/* Overlay */}
      <div id="hero-overlay" className='absolute top-0 left-0 z-5 w-full h-full z-10 bg-[rgba(0,0,0,0.7)]'></div>

      {/* Background Image */}
      <img id='hero-image-bg' className="absolute top-0 left-0 w-full h-full object-cover z-5" src='/images/hero.png' alt="" />

      {/* Hero Content */}
      <div className="container flex  justify-center min-h-full">
          <div className='mb-16 z-10 flex flex-col justify-center items-center pl-[0px] md:pl-[50px]'>
            <h1 className="text-4xl md:text-8xl font-bold uppercase text-gradient">Jason Fleming</h1>
            <p className='mt-6 md:mt-8 text-sm md:text-2xl uppercase font-bold text-center'>
              <span>Front End Developer from New York</span>
              {/* <br className='lg:hidden' /> */}
            
              </p>
          </div>
        </div>
          
    </section>
  )
}

export default Hero