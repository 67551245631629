import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectHero, Overview, Slider, UIShots } from '../components';


const Project = () => {
  const {title} = useParams();

  const [loading, setLoading] = useState(false)
  const [project, setProject] = useState({})
  const [next, setNext] = useState('')

  const fetchProject = async() => {
    setLoading(true)
   try {
     const response = await fetch('/projects.json')
     const data = await response.json();

     const project = data.find((item) => {
      return item.title === title
     });




     let nextProject = data.find((item, index) => {
      if(item.index === project.index + 1){
        return item
      }
     })

     if(nextProject){
      nextProject = nextProject.title
     }
     
     if(!nextProject){
      nextProject = data[0].title
     }


    
     setProject(project)
     setNext(nextProject)

     setLoading(false)
   } catch (error) {
     
   }
  }

  useEffect(() => {
     window.scrollTo(0,0)
    fetchProject();
  }, [title])

  return (
   <>
        {loading ? 
          <div className='min-h-[30vh] flex justify-center items-center'><p>Loading...</p></div> 
            : 
          <ProjectHero 
          mobileImage={project.mobileImage} 
          image={project.image} 
          title={project.title}
          credentials={project.credentials}
         frameworks={project.frameworks}
         about={project.about}
         src={project.src}
         gitLink={project.gitLink}
        />}

        {/* <Overview
         title={project.title}
         frameworks={project.frameworks}
         about={project.about}
         src={project.src}
         gitLink={project.gitLink}
         /> */}
        {/* <Slider challenges={project.challenges} solutions={project.solutions}/> */}
        <Slider />
        <UIShots conclusion={project.conclusion} next={next}/>
   </>
  )
}

export default Project