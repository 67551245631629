import React from 'react'

const Resume = () => {
  return (
    <section>
       <div className="max-w-[700px] mx-auto">
            <img className='max-w-full h-auto' src='/images/resume.png' alt="" />
       </div>
    </section>
  )
}

export default Resume